import React, { useContext, useEffect, useState, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Moment from 'react-moment';
import moment from 'moment';
import {
    Row,
    Col,
    Card,
    Table,
    Modal,
    Button,
    ProgressBar,
} from "react-bootstrap";
import ReactPaginate from "react-paginate";
import ChatBox from "../Components/ChatBox";
import { getAssignedTaskData, getApproverProjectData,getReviewerTrackingData, getNoteData, sendToApproverData, addNoteData, updateTimeCaptureApproveStatusByReviewerData } from '../../../store/actions/TimeCaptureActions';
import { globalLoadingAction } from '../../../store/actions/GlobalAction';

const ApproverTaskList = (props) => {

    const dispatch = useDispatch();
    const history = useHistory();
    const { state } = useLocation();

    let errorsObj = { title: '', desc: '', activity: '', status: '', timeKeeper: '' };
    const [errors, setErrors] = useState(errorsObj);
    const [message, setMessage] = useState('');
    const [noteData, setNoteData] = useState([]);
    const [isNote, setIsNote] = useState(false);
    const [projectId, setProjectId] = useState(null);
    const [loading, setLoading] = useState(false);


    //filter
    const [searchText, setSearchText] = useState('');
    const [sordBy, setSordBy] = useState('');
    const [sort, setSort] = useState(null);
    const [pageNo, setPageNo] = useState(1);
    const [perPage, setPerPage] = useState(10);


    useEffect(() => {
        dispatch(globalLoadingAction(true));
        dispatch(getApproverProjectData(1, perPage, '', '', props.history));
    }, []);


    const handlePageClick = (data) => {
        setPageNo(data.selected + 1);
        dispatch(globalLoadingAction(true));
        dispatch(getApproverProjectData(data.selected + 1, perPage, sordBy, sordBy ? sort ? 'asc' : 'desc' : '', props.history));
    };

    const onAssignHandler = (item) => {
        dispatch(globalLoadingAction(true));
        dispatch(sendToApproverData(item._id, getTrackData, props.history));
    }

    const getTrackData = () => {
        dispatch(getApproverProjectData(pageNo, perPage, sordBy, sordBy ? sort ? 'asc' : 'desc' : '', props.history));
    }

    const onGetNoteHandler = (project_id) => {
        setProjectId(project_id);
        dispatch(globalLoadingAction(true));
        dispatch(getNoteData(project_id, setNotData, props.history));
    }

    const setNotData = (data) => {
        dispatch(globalLoadingAction(false));
        setNoteData(data);
        setIsNote(true);
    }
    const closeHandler = () => {
        setIsNote(false);
        setNoteData([]);
    }
    const onSubmitMessageHandler = () => {
        setMessage("");
        setLoading(true);
        dispatch(addNoteData(projectId, message, AddNotData, props.history));
    }
    const AddNotData = (data) => {
        setNoteData(data);
        setLoading(false);
    }

    const onNameSorting = (val) => {
        dispatch(globalLoadingAction(true));
        dispatch(getApproverProjectData(1, perPage, val, !sort ? 'asc' : 'desc', props.history));
        setSordBy(val);
        setSort(!sort);
        setPageNo(1);
    }

    return (
        <>
            <div className="row">
                <div className="title-body-container">
                    <div className="title-body">
                        <h3>My Approvals</h3>
                        <p>Maintain user information - Add, edit, delete and invite users</p>
                    </div>
                </div>
                <div className="account-setting-content">
                    <Col lg={12}>
                        <Card>
                            <Card.Body>
                                {props.screenVisible ?
                                    <>
                                        {props.timeTrackList.length > 0 ?
                                            <Table responsive>
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            <button type="button" onClick={() => onNameSorting('client_first_name')} className="sorting-btn">
                                                                <strong>Client</strong>
                                                                <span className={`sorting-icon-groups ${sordBy === 'client_first_name' ? sort ? "sorting-asc" : "sorting-dec" : null}`}>
                                                                    <i class="fa fa-sort-asc" aria-hidden="true"></i>
                                                                    <i class="fa fa-sort-desc" aria-hidden="true"></i>
                                                                </span>
                                                            </button>
                                                        </th>
                                                        <th><strong>Project ID</strong></th>
                                                        {/* <th>
                                                            <button type="button" onClick={() => onNameSorting('title')} className="sorting-btn">
                                                                <strong>Project</strong>
                                                                <span className={`sorting-icon-groups ${sordBy === 'title' ? sort ? "sorting-asc" : "sorting-dec" : null}`}>
                                                                    <i class="fa fa-sort-asc" aria-hidden="true"></i>
                                                                    <i class="fa fa-sort-desc" aria-hidden="true"></i>
                                                                </span>
                                                            </button>
                                                        </th> */}
                                                        <th>
                                                            <button type="button" onClick={() => onNameSorting('rate')} className="sorting-btn">
                                                                <strong>Rate</strong>
                                                                <span className={`sorting-icon-groups ${sordBy === 'rate' ? sort ? "sorting-asc" : "sorting-dec" : null}`}>
                                                                    <i class="fa fa-sort-asc" aria-hidden="true"></i>
                                                                    <i class="fa fa-sort-desc" aria-hidden="true"></i>
                                                                </span>
                                                            </button>
                                                        </th>
                                                        <th>
                                                            <strong>Start Date</strong>
                                                        </th>
                                                        <th>
                                                            <strong>End Date</strong>
                                                        </th>
                                                     
                                                        {/* <th className='text-center'>
                                                            <strong>Status</strong>
                                                        </th>
                                                        <th>
                                                            <strong>Comments</strong>
                                                        </th> */}
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {
                                                        props.timeTrackList.map((item, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{item.client_first_name}{" "}{item.client_last_name}</td>
                                                                    {/* <td>{item.project_id}</td> */}
                                                                    <td>
                                                                        <Link
                                                                            to={{
                                                                                pathname: '/approver-time-card',
                                                                                state: {id:item._id}
                                                                            }}
                                                                            className="project-title"
                                                                        >
                                                                            {item.project_id}
                                                                        </Link>
                                                                    </td>
                                                                    <td>{item.rate}</td>
                                                                    <td><Moment format="D MMM YYYY" withTitle>{item.start_date}</Moment></td>
                                                                    <td><Moment format="D MMM YYYY" withTitle>{item.end_date}</Moment></td>
                                                                
                                                                    {/* <td className='text-center'>
                                                                        {(() => {
                                                                            switch (item.approve_status) {
                                                                                case 0:
                                                                                    return (<span className="badge bg-warning text-dark custom-badge">
                                                                                        Pending
                                                                                    </span>);
                                                                                case 1:
                                                                                    return (
                                                                                        <span className="badge bg-success custom-badge">
                                                                                            Approved
                                                                                        </span>
                                                                                    );
                                                                                case 2:
                                                                                    return (
                                                                                        <span className="badge bg-danger custom-badge">
                                                                                            Send back
                                                                                        </span>
                                                                                    );
                                                                                default:
                                                                                    return <h3>-</h3>
                                                                            }
                                                                        })()}

                                                                    </td> */}
                                                                    {/* <td>
                                                                        <div className="d-flex">
                                                                            <button
                                                                                disabled={item.send_to_approver == 1 && item.approve_status !== 2}
                                                                                type="button"
                                                                                onClick={() => onAssignHandler(item)}
                                                                                className="btn btn-success btn-xs me-2"
                                                                            >
                                                                                Send to approver
                                                                            </button>

                                                                        </div>
                                                                    </td> */}
                                                                    <td>
                                                                        {/* <button
                                                                            type="button"
                                                                            onClick={()=>onGetNoteHandler(item._id)}
                                                                            className="btn btn-info btn-xs custom-approval-button"
                                                                        >
                                                                            Note
                                                                        </button> */}
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })
                                                    }
                                                </tbody>
                                            </Table>
                                            :
                                            <div className="nodata-found-view">
                                                <h3>No Transactions</h3>
                                            </div>
                                        }
                                    </>
                                    : null}

                                <div className="pagination-container">
                                    {parseInt(props.totalTrackRecord) > 0 && props.showLoading == false ? (
                                        <div className="page-result alignResult">
                                            <p>Showing{" "}{(pageNo - 1) * perPage + 1 + " - " + ((pageNo - 1) * perPage + props.timeTrackList.length) + " of " + "( " + props.totalTrackRecord + " items )"}</p>
                                        </div>
                                    ) : (
                                        <div></div>
                                    )}

                                    {parseInt(props.totalTrackRecord) > 10 ? (
                                        <ReactPaginate
                                            containerClassName="pagination-gutter false pagination-circle pagination"
                                            pageClassName="page-item"
                                            pageLinkClassName="page-link"
                                            breakLabel="..."
                                            nextLabel={<i class="fa fa-angle-right" aria-hidden="true"></i>}
                                            nextClassName="page-item"
                                            nextLinkClassName="page-link"
                                            previousLabel={<i class="fa fa-angle-left" aria-hidden="true"></i>}
                                            previousClassName="page-item"
                                            previousLinkClassName="page-link"
                                            breakClassName="page-item"
                                            breakLinkClassName="page-link"
                                            renderOnZeroPageCount={null}
                                            activeClassName="active"
                                            onPageChange={handlePageClick}
                                            pageRangeDisplayed={3}
                                            pageCount={props.totalTrackPage}
                                            //initialPage={active - 1}
                                            forcePage={pageNo - 1}
                                        />
                                    ) : null}
                                </div>

                            </Card.Body>
                        </Card>
                    </Col>
                </div>

                <ChatBox
                    show={isNote}
                    data={noteData}
                    value={message}
                    onChangeHandler={(val) => setMessage(val)}
                    onSubmitHandler={() => onSubmitMessageHandler()}
                    closeHandler={closeHandler}
                    loading={loading}
                    currentUser={props.userId}
                />
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        screenVisible: state.timeCapture.screenVisible,
        showLoading: state.timeCapture.showLoading,
        timeTrackList: state.timeCapture.timeTrackList,
        totalTrackPage: state.timeCapture.totalTrackPage,
        totalTrackRecord: state.timeCapture.totalTrackRecord,
        userId: state.auth.userId,
    };
};
export default connect(mapStateToProps)(ApproverTaskList);