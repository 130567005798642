import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import { connect, useDispatch } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
    Row,
    Col,
    Card,
    Table,
    Modal,
    Button,
    Accordion,
    Spinner
} from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { loadingTeamAction, getActiveTeamList } from '../../../store/actions/TeamActions';
import { getClientsData, loadingClientsAction } from '../../../store/actions/ClientsActions';

const AddProject = (props) => {

    const dispatch = useDispatch();
    const history = useHistory();
    const startDateRef = useRef();
    const endDateRef = useRef();

    let errorsObj = { id:'',title: '', desc: '', client: '', group: '', startDate: '', endDate: '', rate: '' };
    const [errors, setErrors] = useState(errorsObj);

    const [id, setId] = useState('');
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [client, seClient] = useState('');
    const [group, setGroup] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [projectId, setProjectId] = useState('');
    const [projectType, setProjectType] = useState('');
    const [status, setStatus] = useState('');
    const [billable, setBillable] = useState(false);
    const [rate, setRate] = useState('');

    useEffect(() => {
        resetValue();
    }, [props.show]);

    const resetValue = () => {
        getClientHandler();
        getGroupHandler();
        const errorObj = { ...errorsObj };
        errorObj.id = '';
        errorObj.title = '';
        errorObj.desc = '';
        errorObj.client = '';
        errorObj.group = '';
        errorObj.startDate = '';
        errorObj.endDate = '';
        errorObj.rate = '';
        setErrors(errorObj);
        if (props.action === 1) {
            setId('');
            setTitle('');
            setDescription('');
            seClient('');
            setGroup('');
            setStartDate('');
            setEndDate('');
            setProjectType('');
            setRate('');
            setBillable(false)
        } else {
            let startDate = new Date(props.data.start_date);
            let endDate = new Date(props.data.end_date);
            setId(props.data.project_id);
            setTitle(props.data.title);
            setDescription(props.data.description);
            seClient(props.data.client_id);
            setGroup(props.data.group_id);
            setStartDate(startDate);
            setEndDate(endDate);
            setProjectId(props.data._id);
            setStatus(props.data.status);
            setProjectType(props.data.project_type);
            setRate(props.data.billable == 1?props.data.rate:'');
            setBillable(props.data.billable == 1 ? true : false)
        }
    }

    function onSubmit(e) {

        e.preventDefault();
        let error = false;
        const errorObj = { ...errorsObj };

        if (id === '') {
            errorObj.id = 'Id is Required';
            error = true;
        }
        // if (title === '') {
        //     errorObj.title = 'Title is Required';
        //     error = true;
        // }
        if (startDate === '') {
            errorObj.startDate = 'Start Date is Required';
            error = true;
        }
        if (endDate === '') {
            errorObj.endDate = 'End Date is Required';
            error = true;
        }
        if (billable && rate === '') {
            errorObj.rate = 'Project rate is Required';
            error = true;
        }

        setErrors(errorObj);
        if (error) {
            return;
        }

        let obj = {
            'projectId': projectId,
            'unique_id':id,
            'title': id,//title,
            'description': description,
            'client': client,
            'group': group,
            'startDate': startDate,
            'endDate': endDate,
            'projectType': projectType,
            'status': status,
            'rate': rate,
            'billable': billable
        };
        props.submitHandler(obj);

    }

    const getClientHandler = () => {
        if (props.clientList == 0) {
            dispatch(loadingClientsAction(true));
            dispatch(getClientsData(props.history));
        }
    }

    const getGroupHandler = () => {
        if (props.activeTeamList == 0) {
            dispatch(loadingTeamAction(props.history));
            dispatch(getActiveTeamList(props.history));
        }
    }

    const onBilableHandler = (check) => {
        setBillable(check);
    }
    return (
        <Fragment>
            <Modal
                className="fade bd-example-modal-sm"
                size="lg"
                onHide={props.closeHandler}
                show={props.show}
            >
                <div className="add-user-modal-form-view client-modal">
                    <button onClick={props.closeHandler} className="modal-cross-btn"><i class="fa fa-times" aria-hidden="true"></i></button>
                    <div className="modal-title-view">
                        <h3>{props.action == 1 ? 'Create' : 'Edit'} Project</h3>
                        <p>Enter Project Details</p>
                    </div>
                    <div className="basic-form">

                        <form onSubmit={onSubmit}>

                            <div className="row">
                            <div className="form-group form-group-modal-custom mb-3 col-6">
                                    <label className="mb-1">
                                        ID
                                    </label>
                                    <input className="form-control" value={id} onChange={(e) => setId(e.target.value)} />
                                    {errors.id && <div className="text-danger fs-12">{errors.id}</div>}
                                </div>
                                {/* <div className="form-group form-group-modal-custom mb-3 col-6">
                                    <label className="mb-1">
                                        Title
                                    </label>
                                    <input className="form-control" value={title} onChange={(e) => setTitle(e.target.value)} />
                                    {errors.title && <div className="text-danger fs-12">{errors.title}</div>}
                                </div> */}

                                <div className="form-group form-group-modal-custom mb-3 col-6">
                                    <label className="mb-1">
                                        Description
                                    </label>
                                    <input className="form-control" value={description} onChange={(e) => setDescription(e.target.value)} />
                                    {errors.desc && <div className="text-danger fs-12">{errors.desc}</div>}
                                </div>

                                <div className="form-group form-group-modal-custom mb-3 col-6">
                                    <label className="mb-1">
                                        Client
                                    </label>
                                    <div className="select-drop-down">
                                        {props.clientLoading ?
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                            :
                                            <span><i class="fa fa-angle-down"></i></span>
                                        }
                                        <select
                                            defaultValue={"option"}
                                            class="form-control select-control"
                                            value={client}
                                            onClick={getClientHandler}
                                            onChange={(e) => seClient(e.target.value)}>
                                            <option></option>
                                            {
                                                props.clientList.map((item, index) => {
                                                    return <option key={index} value={item._id}>{item.first_name}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                    {errors.client && <div className="text-danger fs-12">{errors.client}</div>}
                                </div>
                                <div className="form-group form-group-modal-custom mb-3 col-6">
                                    <label className="mb-1">
                                        User Group
                                    </label>
                                    <div className="select-drop-down">
                                        {props.teamLoading ?
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                            :
                                            <span><i class="fa fa-angle-down"></i></span>
                                        }
                                        <select
                                            defaultValue={"option"}
                                            class="form-control select-control"
                                            value={group}
                                            onClick={getGroupHandler}
                                            onChange={(e) => setGroup(e.target.value)}>
                                            <option></option>
                                            {
                                                props.activeTeamList.map((item, index) => {
                                                    return <option key={index} value={item._id}>{item.groupId}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                    {errors.group && <div className="text-danger fs-12">{errors.group}</div>}
                                </div>

                                <div className="form-group form-group-modal-custom mb-3 col-6">
                                    <label className="mb-1">
                                        Start Date
                                    </label>
                                    <div className="form-calendar">
                                        <DatePicker
                                            ref={startDateRef}
                                            selected={startDate}
                                            className="form-control"
                                            placeholderText=""
                                            onChange={(date) => setStartDate(date)}
                                        />
                                        <button type='button' className="form-calendar-btn"
                                            onClick={() => startDateRef.current.setOpen(true)}
                                        >
                                            <i class="fa-regular fa-calendar-days"></i>
                                        </button>
                                    </div>
                                    {errors.startDate && <div className="text-danger fs-12">{errors.startDate}</div>}
                                </div>

                                <div className="form-group form-group-modal-custom mb-3 col-6">
                                    <label className="mb-1">
                                        End Date
                                    </label>
                                    <div className="form-calendar">
                                        <DatePicker
                                            ref={endDateRef}
                                            selected={endDate}
                                            className="form-control"
                                            placeholderText=""
                                            onChange={(date) => setEndDate(date)}
                                        />
                                        <button type='button' className="form-calendar-btn"
                                            onClick={() => endDateRef.current.setOpen(true)}
                                        >
                                            <i class="fa-regular fa-calendar-days"></i>
                                        </button>
                                    </div>
                                    {errors.endDate && <div className="text-danger fs-12">{errors.endDate}</div>}
                                </div>

                                <div className="form-group form-group-modal-custom mb-3 col-6">
                                    <label className="mb-1">
                                        Project type
                                    </label>
                                    <div className="select-drop-down">
                                        <span><i class="fa fa-angle-down"></i></span>
                                        <select
                                            defaultValue={"option"}
                                            class="form-control select-control"
                                            value={projectType}
                                            onChange={(e) => setProjectType(e.target.value)}>
                                            <option></option>
                                            <option value='1'>Internal</option>
                                            <option value='2'>External</option>
                                        </select>
                                    </div>
                                    {errors.group && <div className="text-danger fs-12">{errors.group}</div>}
                                </div>

                                <div className="col-6">
                                    <div className="form-check custom-checkbox checkbox-select mx-2 pt-5">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="billable"
                                            checked={billable}
                                            onClick={(e) => onBilableHandler(e.target.checked)}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="billable"
                                        >
                                            {" "}Billable
                                        </label>
                                    </div>
                                </div>
                                {billable ?
                                    <div className="form-group form-group-modal-custom mb-3 col-6">
                                        <label className="mb-1">
                                            Rate (per hour)
                                        </label>
                                        <input className="form-control" type="number" value={rate} onChange={(e) => setRate(e.target.value)} />
                                        {errors.rate && <div className="text-danger fs-12">{errors.rate}</div>}
                                    </div>
                                    : null}
                            </div>

                            <div className="modal-button-group mt-4">
                                <button type="button" onClick={props.closeHandler} className="btn form-cencel-btn">
                                    Cancel
                                </button>
                                <button type="submit" className="btn btn-primary role-form-btn">
                                    {props.action == 1 ? "Save" : "Update"}
                                </button>
                            </div>
                        </form>

                    </div>
                </div>
            </Modal>
        </Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        teamLoading: state.team.showLoading,
        activeTeamList: state.team.activeTeamList,
        clientLoading: state.clients.showLoading,
        clientList: state.clients.clientList
    };
};
export default connect(mapStateToProps)(AddProject);