import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Col, Card, Tab, Nav } from "react-bootstrap";
import PendingTab from '../../components/Components/PendingTab';
import ApprovedTab from '../../components/Components/ApprovedTab';
import RejectTab from '../../components/Components/RejectTab';

const ApproverTimeCard = (props) => {
    const { state } = useLocation();
    const [activeTab, setActiveTab] = useState("pending list");

    // if (!state || !state.id) {
    //     return <p>Error: No project ID provided</p>;
    // }

    // const tabData = [
    //     {
    //         name: "Pending List",
    //         content: <PendingTab projectId={state.id} />,
    //     },
    //     {
    //         name: "Approved List",
    //         content: <ApprovedTab projectId={state.id} />,
    //     },
    //     {
    //         name: "Send Back List",
    //         content: <RejectTab projectId={state.id} />,
    //     }
    // ];

    return (
        <div className="row">
            <div className="breadcrumb-container">
                <ul>
                    <li>
                        <Link to={"/approver-task"}>My Approvals</Link>
                    </li>
                    <li><i className="fa fa-angle-right"></i></li>
                    <li>
                        <Link to={"/approver-time-card"}>Timesheet</Link>
                    </li>
                </ul>
            </div>
            <div className="title-body mb-3">
                <h3>Timesheet</h3>
            </div>
            <div className="account-setting-content">
                <Col lg={12}>
                    <Card>
                        <Card.Body>
                            {/* <Tab.Container
                                defaultActiveKey={activeTab}
                                onSelect={(k) => setActiveTab(k)}
                            >
                                <Nav as="ul" className="nav-pills mb-4 light">
                                    {tabData.map((data, i) => (
                                        <Nav.Item as="li" key={i}>
                                            <Nav.Link eventKey={data.name.toLowerCase()}>
                                                {data.name}
                                            </Nav.Link>
                                        </Nav.Item>
                                    ))}
                                </Nav>
                                <Tab.Content className="">
                                    {tabData.map((data, i) => (
                                        <Tab.Pane
                                            eventKey={data.name.toLowerCase()}
                                            key={i}
                                        >
                                            {activeTab === data.name.toLowerCase() && data.content}
                                        </Tab.Pane>
                                    ))}
                                </Tab.Content>
                            </Tab.Container> */}
                            <PendingTab projectId={state.id} />
                        </Card.Body>
                    </Card>
                </Col>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        userId: state.auth.userId,
    };
};

export default connect(mapStateToProps)(ApproverTimeCard);
