import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import { connect, useDispatch } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
    Row,
    Col,
    Card,
    Table,
    Modal,
    Button,
    Accordion,
    Spinner
} from "react-bootstrap";
import DatePicker from "react-datepicker";
import Select from 'react-select';
import "react-datepicker/dist/react-datepicker.css";

const AddTask = (props) => {

    const dispatch = useDispatch();

    let errorsObj = { id:'',title: '', desc: '', activity: '', status: '', timeKeeper: '' };
    const [errors, setErrors] = useState(errorsObj);

    const [id, setId] = useState('');
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [activity, setActivity] = useState('');
    const [timeKeeper, setTimeKeeper] = useState([]);
    const [status, setStatus] = useState('');
    const [taskId, setTaskId] = useState('');
    const [optionsArray, setOptionsArray] = useState([]);

    useEffect(() => {
        resetValue();
    }, [props.show]);

    const resetValue = () => {
        const errorObj = { ...errorsObj };
        errorObj.id = '';
        errorObj.title = '';
        errorObj.desc = '';
        errorObj.activity = '';
        errorObj.timeKeeper = '';
        errorObj.status = '';
        setErrors(errorObj);
        if (props.action === 1) {
            setId('');
            setTitle('');
            setDescription('');
            setActivity('');
            setTimeKeeper([]);
            setTaskId('');
        } else {
            setId(props.data.task_id);
            setTitle(props.data.title);
            setDescription(props.data.description);
            setTaskId(props.data._id);
            setActivity(props.data.activity);
            setTimeKeeper(props.data.time_keepers);
            setEmp(props.data.time_keepers);
            setStatus(props.data.status);
        }
    }

    function onSubmit(e) {

        e.preventDefault();

        let error = false;
        const errorObj = { ...errorsObj };

        if (id === '') {
            errorObj.id = 'Id is Required';
            error = true;
        }
        // if (title === '') {
        //     errorObj.title = 'Title is Required';
        //     error = true;
        // }
        if (description === '') {
            errorObj.desc = 'Description is Required';
            error = true;
        }
        // if (activity === '') {
        //     errorObj.activity = 'Activity is Required';
        //     error = true;
        // }
        // if (timeKeeper.length == 0) {
        //     errorObj.timeKeeper = 'Time Keeper is Required';
        //     error = true;
        // }
        setErrors(errorObj);
        if (error) {
            return;
        }

        let timeKeeperArray = [];
        timeKeeper.forEach((item, index) => {
            timeKeeperArray.push(item.value);
        });

        let obj = {
            'taskId': taskId,
            'unique_id':id,
            'title': title,
            'description': description,
            'activity': activity,
            'timeKeeper': timeKeeperArray,
            'status': status,
        };
        props.submitHandler(obj);

    }

    const onMultiSelectHandler = (val) => {
        setTimeKeeper(val);
    }

    const setEmp = (val) => {
        let array = [];
        let dataArray = [...props.option];
        let id;
        dataArray.forEach((emp, i) => {
            id = val.includes(emp.value);
            if (id) {
                array.push(emp);
            }
        });
        setTimeKeeper(array);
    }

    return (
        <Fragment>
            <Modal
                className="fade bd-example-modal-sm"
                size="lg"
                onHide={props.closeHandler}
                show={props.show}
            >
                <div className="add-user-modal-form-view">
                    <button onClick={props.closeHandler} className="modal-cross-btn"><i class="fa fa-times" aria-hidden="true"></i></button>
                    <div className="modal-title-view">
                        <h3>{props.action == 1 ? 'Create' : 'Edit'} Task</h3>
                        <p>Enter Task Details</p>
                    </div>
                    <div className="basic-form">
                        <div className="basic-form">
                            <form onSubmit={onSubmit}>

                                <div className="row">
                                <div className="form-group form-group-modal-custom mb-3 col-6">
                                        <label className="mb-1">
                                            Id
                                        </label>
                                        <input className="form-control" value={id} onChange={(e) => setId(e.target.value)} />
                                        {errors.id && <div className="text-danger fs-12">{errors.id}</div>}
                                    </div>
                                    {/* <div className="form-group form-group-modal-custom mb-3 col-6">
                                        <label className="mb-1">
                                            Title
                                        </label>
                                        <input className="form-control" value={title} onChange={(e) => setTitle(e.target.value)} />
                                        {errors.title && <div className="text-danger fs-12">{errors.title}</div>}
                                    </div> */}

                                    <div className="form-group form-group-modal-custom mb-3 col-6">
                                        <label className="mb-1">
                                            Description
                                        </label>
                                        <input className="form-control" value={description} onChange={(e) => setDescription(e.target.value)} />
                                        {errors.desc && <div className="text-danger fs-12">{errors.desc}</div>}
                                    </div>

                                    <div className="form-group form-group-modal-custom mb-3 col-6">
                                        <label className="mb-1">
                                            Activity
                                        </label>
                                        <input className="form-control" value={activity} onChange={(e) => setActivity(e.target.value)} />
                                        {errors.activity && <div className="text-danger fs-12">{errors.activity}</div>}
                                    </div>

                                    <div className="form-group mb-3 col-6">
                                        <label className="mb-1">
                                            Time Keepers
                                        </label>
                                        <div className="task-multi-select">
                                            <Select
                                                defaultValue={timeKeeper}
                                                isMulti
                                                name="emp"
                                                options={props.option}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                placeholder=""
                                                onChange={onMultiSelectHandler}
                                            />
                                        </div>
                                        {errors.timeKeeper && <div className="text-danger fs-12">{errors.timeKeeper}</div>}
                                    </div>

                                </div>

                                <div className="modal-button-group mt-4">
                                    <button type="button" onClick={props.closeHandler} className="btn form-cencel-btn">
                                        Cancel
                                    </button>
                                    <button type="submit" className="btn btn-primary role-form-btn">
                                        {props.action == 1 ? "Save" : "Update"}
                                    </button>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </Modal>
        </Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        teamLoading: state.team.showLoading,
        activeTeamList: state.team.activeTeamList,
        clientLoading: state.clients.showLoading,
        clientList: state.clients.clientList
    };
};
export default connect(mapStateToProps)(AddTask);