import React, { useContext, useEffect, useState, useRef } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Row, Col, Card, Table, Modal, Button, Spinner } from "react-bootstrap";
import swal from "sweetalert";
import * as utility from '../../../utility/Utility';
import ReactPaginate from "react-paginate";
import { globalLoadingAction } from '../../../store/actions/GlobalAction';
import { getKeeperTeamListData } from '../../../store/actions/EmployeeActions';
import { getClientsData, addClientsData } from '../../../store/actions/ClientsActions';
import { getProjectByClientIdList, addProjectData, addActivitiesData, getProjectDetailsByIdData, getProjectActivitiesListData, checkActivityExistance, addTaskData, getTaskList, getProjectActivitiesCheckExistanceAction, getAccessableTaskList } from '../../../store/actions/ProjectActions';
import { addTimeCapture, updateTimeCapture, updateTimeCaptureListData, getTimeCaptureDetails, getNoteData, addNoteData, sendToReviewerData, setSingleDataObject, addTimeBillable, stopTimeAction, removeTimeCaptureData, updateTimeCaptureApproveStatusData } from '../../../store/actions/TimeCaptureActions';
import { getSystemUserConfigData } from '../../../store/actions/SystemDefaultActions';
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AddClient from '../Components/AddClient';
import AddProject from '../Components/AddProject';
import AddActivitie from '../Components/AddActivitie';
import AddTask from '../Components/AddTask';
import DateRangeModal from '../Components/DateRangeModal';
import ChatBox from "../Components/ChatBox";

const TimeCapture = (props) => {

    const dispatch = useDispatch();
    const history = useHistory();
    const { state } = useLocation();
    const calendarRef = useRef();
    const startDateRef = useRef();
    const endDateRef = useRef();

    let errorsObj = { client: '', project: '', activity: '', task: '', description: '' };
    const [errors, setErrors] = useState(errorsObj);

    const [client, setClient] = useState('');
    const [project, setProject] = useState('');
    const [activity, setActivity] = useState('');
    const [task, setTask] = useState('');
    const [description, setDescription] = useState('');
    const [billing, setBilling] = useState(false);
    const [addModal, setAddModal] = useState(false);
    const [isConfirm, setIsConfirm] = useState(false);
    const [clientModal, setClientModal] = useState(false);
    const [projectModal, setProjectModal] = useState(false);
    const [activitieModal, setActivitieModal] = useState(false);
    const [taskModal, setTaskModal] = useState(false);
    const [optionsArray, setOptionsArray] = useState([]);
    const [action, setAction] = useState('');
    const [timeData, setTimeData] = useState({});
    const [projectStartDate, setProjectStartDate] = useState('');
    const [projectEndDate, setProjectEndDate] = useState('');
    const [getActionType, setGetActionType] = useState(false);
    const [isTask, setIsTask] = useState(true);
    const [isDateRange, setIsDateRange] = useState(false);

    //update time capture
    const [updateRecordId, setUpdateRecordId] = useState('');
    const [updateClient, setUpdateClient] = useState('');
    const [updateProject, setUpdateProject] = useState('');
    const [updateActivity, setUpdateActivity] = useState('');
    const [updateTask, setUpdateTask] = useState('');
    const [updateStartDate, setUpdateStartDate] = useState('');
    const [updateEndDate, setUpdateEndDate] = useState();
    const [updateDes, setUpdateDes] = useState('');
    const [updateBilling, setUpdateBilling] = useState(false);

    const offset = useSelector(state => state.timeCapture.initialOffset);
    const isRunning = useSelector(state => state.timeCapture.isRunning);
    const timeDetails = useSelector(state => state.timeCapture.timeDetails);

    //note
    const [message, setMessage] = useState('');
    const [trackId, setTrackId] = useState(null);
    const [noteData, setNoteData] = useState([]);
    const [isNote, setIsNote] = useState(false);
    const [loading, setLoading] = useState(false);

    //filter

    const [filterType, setFilterType] = useState(0);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [pageNo, setPageNo] = useState(1);
    const [perPage, setPerPage] = useState('');
    const [sordBy, setSordBy] = useState('');
    const [sort, setSort] = useState(null);

    const chackBox = document.querySelector("#billing-check");

    useEffect(() => {
        dispatch(getTimeCaptureDetails(props?.configDetails?.filter_option_id ? parseInt(props?.configDetails?.filter_option_id) : 0, '', '', 1, props?.configDetails?.pagination_value ? parseInt(props?.configDetails?.pagination_value) : 0, '', '', props.history));
        setPerPage(props.configDetails.pagination_value);
        setFilterType(props.configDetails.filter_option_id);
    }, [props.configDetails]);

    useEffect(() => {
        dispatch(getSystemUserConfigData(1, props.systemAdmin, props.history));
        dispatch(getClientsData("", "", "", 1, 0, props.history));
        onLoadStart();
    }, []);

    useEffect(() => {
        if (props.projectDetails?.start_date) {
            setProjectStartDate(new Date(props.projectDetails.start_date));
            setProjectEndDate(new Date(props.projectDetails.end_date));
        }
    }, [props.projectDetails]);

    useEffect(() => {
        if (getActionType) {
            dispatch(getTimeCaptureDetails(filterType ? parseInt(filterType) : 0, '', '', parseInt(pageNo), perPage ? parseInt(perPage) : 0, '', '', props.history));
            if (!props.acknowledged) {
                dispatch(getProjectActivitiesCheckExistanceAction(true));
                setClient('');
                setProject('');
                setActivity('');
                setTask('');
                setDescription('');
                setBilling(false);
                chackBox.checked = false;
            }
        }
    }, [props.acknowledged]);

    useEffect(() => {
        dispatch(globalLoadingAction(false));
    }, [props.list]);

    useEffect(() => {
        if (props.activitiesList.length > 0 && props.taskList.length == 0) {
            setIsTask(false);
        } else {
            setIsTask(true);
        }
    }, [props.activitiesList, props.taskList]);


    useEffect(() => {
        let array = [];
        let obj = {};
        props.groupKeeperList.forEach((emp, index) => {
            obj = { value: emp._id, label: emp.first_name };
            array.push(obj);
        });
        setOptionsArray(array);
    }, [props.groupKeeperList]);

    const onLoadStart = () => {
        if (state) {
            dispatch(getProjectDetailsByIdData(state.project_id, props.history));
            dispatch(getProjectActivitiesListData(state.project_id, '', '', '', 1, 0, props.systemAdmin, props.history));
            setClient(state.client_id);
            setProject(state.project_id);
            setActivity(state.activity_id);
            setTask(state._id);
            setDescription(state.description);
            dispatch(globalLoadingAction(true));
            //dispatch(addTimeCapture(state.client_id, state.project_id, state.activity_id, state._id, state.description, 0, new Date(), props.history));
        }

    }

    const milliseconds = offset % 1000;
    const seconds = Math.floor((offset / 1000) % 60);
    const minutes = Math.floor((offset / (1000 * 60)) % 60);
    const hours = Math.floor((offset / (1000 * 60 * 60)) % 24);

    const onSubmitHandler = () => {

        let error = false;
        const currentDate = new Date();
        const errorObj = { ...errorsObj };

        if (client === '') {
            errorObj.client = 'Client is Required';
            error = true;
        }
        if (project === '') {
            errorObj.project = 'Project is Required';
            error = true;
        }
        //console.log(currentDate, '...cd.32............', projectEndDate);
        if (currentDate >= projectStartDate && currentDate >= projectEndDate) {
            //console.log(currentDate, '...cd.............', projectStartDate);
            // utility.showError('Date range should be between project start date and end date.');
            setIsDateRange(true);
            error = true;
        }
        // if (activity === '') {
        //     errorObj.activity = 'Activity is Required';
        //     error = true;
        // }
        // if (task === '') {
        //     errorObj.task = 'Task is Required';
        //     error = true;
        // }
        // if (description === '') {
        //     errorObj.description = 'Description is Required';
        //     error = true;
        // }

        setErrors(errorObj);
        if (error) {
            return;
        }
        setGetActionType(false);
        dispatch(globalLoadingAction(true));
        dispatch(addTimeCapture(
            client == "0" ? "" : client,
            project == "0" ? "" : project,
            activity == "0" ? "" : activity,
            task == "0" ? "" : task,
            description,
            billing ? 1 : 0,
            currentDate,
            props.history));

        // setClient('');
        // setProject('');
        // setActivity('');
        // setTask('');
        // setDescription('');
        // setBilling(false);
        // chackBox.checked = false;

    }

    const onUpdateHandler = () => {
        setGetActionType(true);
        setAction(false);
        dispatch(globalLoadingAction(true));
        dispatch(updateTimeCapture(timeDetails.track_id,
            timeDetails.client_id,
            timeDetails.project_id,
            timeDetails.activity_id,
            timeDetails.task_id,
            timeDetails.description,
            timeDetails.billable,
            new Date(),
            props.history));
        setClient('');
        setProject('');
        setActivity('');
        setTask('');
        setDescription('');
        setBilling(false);
        setFilterType(1);
        setPageNo(1);
        setStartDate('');
        setEndDate('');
        setIsTask(true);
        clearSort();
    }


    const onPlayHandler = (item, index) => {
        dispatch(getProjectByClientIdList(item.client_id, props.history));
        if (item.project_id == "" || item.project_id == 0) {
            dispatch(getProjectActivitiesCheckExistanceAction(false));
        } else {
            dispatch(getProjectDetailsByIdData(item.project_id, props.history));
            dispatch(checkActivityExistance(item.project_id, props.history));
            dispatch(getProjectActivitiesListData(item.project_id, '', '', '', 1, 0, props.systemAdmin, props.history));
            dispatch(getAccessableTaskList(item.activity_id, "", props.history));
        }

        setAction(true);
        setTimeData(item);
        setIsConfirm(true);
        clearSort();
    }

    const onConfirmHandler = () => {
        setClient(timeData?.client_id ? timeData.client_id : '');
        setProject(timeData?.project_id ? timeData.project_id : '');
        setActivity(timeData?.activity_id ? timeData.activity_id : '');
        setTask(timeData?.task_id ? timeData.task_id : '');
        setDescription(timeData?.description ? timeData.description : '');
        setBilling(timeData?.billable == 1 ? true : false);
        setProjectEndDate(new Date(timeData.project_end_date));
        setProjectStartDate(new Date(timeData.project_start_date));
        setIsConfirm(false);
        //dispatch(globalLoadingAction(true));
        //dispatch(addTimeCapture(timeData?.client_id, timeData?.project_id, timeData?.activity_id, timeData?.task_id, timeData?.description, timeData?.billable, new Date(), props.history));
    }

    const onCancelHandler = () => {
        setIsConfirm(false);
        setIsTask(true);
        dispatch(getProjectActivitiesCheckExistanceAction(true));
    }

    const onDeleteHandler = (id) => {

        swal({
            title: "Are you sure?",
            text:
                "You want to remove this item !",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                if (props.projectList.length == 1) {
                    dispatch(removeTimeCaptureData(
                        id,
                        parseInt(filterType),
                        startDate ? moment(startDate).format('YYYY-MM-DD') : '',
                        endDate ? moment(endDate).format('YYYY-MM-DD') : '',
                        pageNo - 1,
                        perPage ? parseInt(perPage) : 20,
                        sordBy, sordBy ? sort ? 'asc' : 'desc' : '',
                        props.history));
                    setPageNo(pageNo - 1);
                }
                else {
                    dispatch(removeTimeCaptureData(
                        id,
                        parseInt(filterType),
                        startDate ? moment(startDate).format('YYYY-MM-DD') : '',
                        endDate ? moment(endDate).format('YYYY-MM-DD') : '',
                        parseInt(pageNo),
                        perPage ? parseInt(perPage) : 20,
                        sordBy, sordBy ? sort ? 'asc' : 'desc' : '',
                        props.history));
                }

            }
        })
    }

    const getTotalTime = () => {
        let arry = [...props.list];
        let totalSeconds = 0;

        arry.forEach((data) => {
            let splitTime = data.time_difference.split(':');
            totalSeconds += parseInt(splitTime[0]) * 3600 + parseInt(splitTime[1]) * 60 + parseInt(splitTime[2]);
        });

        let hours = Math.floor(totalSeconds / 3600);
        let minutes = Math.floor((totalSeconds % 3600) / 60);
        let seconds = totalSeconds % 60;

        // Format with leading zeros
        let formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

        return formattedTime;
    }

    const clientHandler = (id) => {
        if (id === "add") {
            setClientModal(true);
        } else {
            setClient(id);
            setProject('');
            setActivity('');
            setTask('');
            dispatch(getProjectByClientIdList(id, props.history));
            if (id) {
                const errorObj = { ...errors };
                errorObj.client = "";
                setErrors(errorObj);
            }
        }
    }

    const onProjectHandler = (value) => {
        if (value == "add") {
            setProjectModal(true);
        } else {
            setProject(value);
            setActivity('');
            setTask('');
            if (value == "0") {
                dispatch(getProjectActivitiesCheckExistanceAction(false));
            } else if (value !== "" || value !== "0") {
                dispatch(getProjectActivitiesListData(value, '', '', '', 1, 0, props.systemAdmin, props.history));
                dispatch(getProjectDetailsByIdData(value, props.history));
                dispatch(checkActivityExistance(value, props.history));
            }
            if (value) {
                const errorObj = { ...errors };
                errorObj.project = "";
                setErrors(errorObj);
            }
        }
    }

    const getGroup = () => {
        const list = [...props.projectList];
        const index = list.findIndex((item) => item._id === project);
        let group_id = list[index].group_id;
        dispatch(getKeeperTeamListData(group_id, props.history));
    }

    const onActivityHandler = (value) => {
        if (value == 'add') {
            setActivitieModal(true);
        } else {
            setActivity(value);
            setTask('');
            if (value == "0") {
                setIsTask(false);
            } else if (value !== "" || value !== "0") {
                dispatch(getAccessableTaskList(value, "", props.history));
                getGroup();
            }
        }
    }

    const onTaskHandler = (value) => {
        if (value == 'add') {
            setTaskModal(true);
        } else {
            setTask(value);
        }
    }

    const onBillableHandler = (element, item) => {
        dispatch(globalLoadingAction(true));
        dispatch(addTimeBillable(
            item._id,
            element.target.checked ? 1 : 0,
            filterType ? parseInt(filterType) : 0,
            startDate ? moment(startDate).format('YYYY-MM-DD') : '',
            endDate ? moment(endDate).format('YYYY-MM-DD') : '',
            parseInt(pageNo),
            perPage ? parseInt(perPage) : 20,
            sordBy, sordBy ? sort ? 'asc' : 'desc' : '',
            props.history));
    }

    const onGoBackHandler = () => {
        history.goBack();
    }

    const clientModalHandler = () => {
        setClientModal(true);
    }

    const onClientHandler = (data) => {
        dispatch(globalLoadingAction(true));
        dispatch(addClientsData(
            data.taxId,
            data.idType,
            data.other_id_type,
            data.tax_no,
            data.alt,
            data.username,
            data.organization_id,
            data.organization_name,
            data.organization_address,
            data.address1,
            data.address2,
            data.country,
            data.state,
            data.city,
            data.zipcode,
            data.email,
            data.mobile,
            data.status,
            data.comPassword,
            data.countryCode,
            data.approverName,
            data.approverEmail,
            data.approverMobileCode,
            data.approverMobile,
            data.reviewerName,
            data.reviewerEmail,
            data.reviewerMobileCode,
            data.reviewerMobile,
            data.billingAddress,
            data.type,
            0,
            props.history));
        setClientModal(false);
    }

    const onAddProjectHandler = (data) => {
        dispatch(globalLoadingAction(true));
        dispatch(addProjectData(data.unique_id, data.title, data.description, data.client,
            data.group, moment(data.startDate).format('YYYY-MM-DD'), moment(data.endDate).format('YYYY-MM-DD'),
            data.projectType ? parseInt(data.projectType) : 0, data.billable ? parseInt(data.rate) : 0, data.billable ? 1 : 0, 0, props.history));
        setProjectModal(false);
    }

    const onAddActivitieHandler = (data) => {
        dispatch(globalLoadingAction(true));
        dispatch(addActivitiesData(project, data.unique_id,
            data.title, data.description, moment(data.startDate).format('YYYY-MM-DD'),
            moment(data.endDate).format('YYYY-MM-DD'), 0, props.history));
        setActivitieModal(false);
    }

    const onAddTaskHandler = (data) => {
        dispatch(globalLoadingAction(true));
        dispatch(addTaskData(project, activity, data.unique_id, data.title, data.description,
            data.activity, data.timeKeeper, 1, 0, props.history));
        setTaskModal(false);

    }

    const clearSort = () => {
        setSordBy('');
        setSort(null);
    }

    const onDateHandler = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        dispatch(globalLoadingAction(true));
        dispatch(getTimeCaptureDetails(4, moment(start).format('YYYY-MM-DD'), moment(end).format('YYYY-MM-DD'), 1, perPage ? parseInt(perPage) : 20, '', '', props.history));
        setPageNo(1);
        setFilterType(4);
        clearSort();

    }

    const handlePageClick = (data) => {
        setPageNo(data.selected + 1);
        dispatch(globalLoadingAction(true));
        dispatch(getTimeCaptureDetails(filterType ? parseInt(filterType) : 0, startDate ? moment(startDate).format('YYYY-MM-DD') : '', endDate ? moment(endDate).format('YYYY-MM-DD') : '', data.selected + 1, perPage ? parseInt(perPage) : 20, sordBy, sordBy ? sort ? 'asc' : 'desc' : '', props.history));
    };

    const onCleanHandler = () => {
        dispatch(globalLoadingAction(true));
        dispatch(getTimeCaptureDetails(0, '', '', 1, 10, '', '', props.history));
        setFilterType(0);
        setStartDate('');
        setEndDate('');
        setPageNo(1);
        setPerPage(10);
        clearSort();
    }

    const onPageHandler = (val) => {
        setPerPage(val);
        dispatch(globalLoadingAction(true));
        dispatch(getTimeCaptureDetails(filterType ? parseInt(filterType) : 0, startDate ? moment(startDate).format('YYYY-MM-DD') : '', endDate ? moment(endDate).format('YYYY-MM-DD') : '', 1, val ? parseInt(val) : 20, '', '', props.history));
        setPageNo(1);
        clearSort();
    }

    const onFilterTypeHandler = (val) => {
        setFilterType(val);
        dispatch(globalLoadingAction(true));
        dispatch(getTimeCaptureDetails(parseInt(val), '', '', 1, perPage ? parseInt(perPage) : 10, '', '', props.history));
        setPageNo(1);
        setStartDate('');
        setEndDate('');
        clearSort();
    }

    const onUpdateProjectHandler = (val) => {
        setUpdateProject(val);
        setUpdateActivity('');
        setUpdateTask('');
        dispatch(getProjectDetailsByIdData(val, props.history));
        dispatch(getProjectActivitiesListData(val, '', '', '', 1, 0, props.systemAdmin, props.history));
    }

    const onUpdateActivityHandler = (val) => {
        setUpdateActivity(val);
        setUpdateTask('');
        //dispatch(getTaskList(val, '', '', '', 1, 0, props.history));
        dispatch(getAccessableTaskList(val, "", props.history));
    }

    const onEditHandler = (item) => {
        dispatch(getProjectByClientIdList(item.client_id, props.history));
        dispatch(getProjectActivitiesListData(item.project_id, '', '', '', 1, 0, props.systemAdmin, props.history));
        dispatch(getAccessableTaskList(item.activity_id, "", props.history));
        setUpdateRecordId(item._id);
        setUpdateClient(item.client_id);
        setUpdateProject(item.project_id);
        setUpdateActivity(item.activity_id);
        setUpdateTask(item.task_id);
        setUpdateStartDate(new Date(item.start_time));
        setUpdateEndDate(new Date(item.end_time));
        setProjectStartDate(new Date(item.project_start_date));
        setProjectEndDate(new Date(item.project_end_date));
        setUpdateDes(item.description);
        setUpdateBilling(item.billable == 1 ? true : false);
        setAddModal(true);
    }

    function onUpdateData(e) {
        e.preventDefault();
        const start_Date = new Date(updateStartDate);
        const end_Date = new Date(updateEndDate);
        let error = false;
        if (start_Date >= projectStartDate && end_Date >= projectEndDate) {
            setIsDateRange(true);
            error = true;
        }
        if (error) {
            return;
        }

        dispatch(globalLoadingAction(true));
        dispatch(updateTimeCaptureListData(
            updateRecordId == "0" ? "" : updateRecordId,
            updateClient == "0" ? "" : updateClient,
            updateProject == "0" ? "" : updateProject,
            updateActivity == "0" ? "" : updateActivity,
            updateTask == "0" ? "" : updateTask,
            updateDes,
            updateBilling ? 1 : 0,
            updateStartDate,
            updateEndDate,
            filterType ? parseInt(filterType) : 0,
            startDate ? moment(startDate).format('YYYY-MM-DD') : '',
            endDate ? moment(endDate).format('YYYY-MM-DD') : '',
            parseInt(pageNo),
            perPage ? parseInt(perPage) : 20,
            sordBy, sordBy ? sort ? 'asc' : 'desc' : '',
            props.history));
        setAddModal(false);
    }

    // const onAssignHandler = (item) => {
    //     dispatch(globalLoadingAction(true));
    //     dispatch(sendToReviewerData(item._id, filterType ? parseInt(filterType) : 0, startDate ? moment(startDate).format('YYYY-MM-DD') : '', endDate ? moment(endDate).format('YYYY-MM-DD') : '', pageNo, perPage ? parseInt(perPage) : 0, sordBy, sordBy ? sort ? 'asc' : 'desc' : '', props.history));
    // }

    const closeHandler = () => {
        setIsNote(false);
        setNoteData([]);
    }

    const onSubmitMessageHandler = () => {
        setMessage("");
        setLoading(true);
        dispatch(addNoteData(trackId, message, AddNotData, props.history));
    }
    const AddNotData = (data) => {
        setNoteData(data);
        setLoading(false);
    }
    const onGetNoteHandler = (track_id) => {
        setTrackId(track_id);
        dispatch(globalLoadingAction(true));
        dispatch(getNoteData(track_id, setNotData, props.history));
    }

    const setNotData = (data) => {
        dispatch(globalLoadingAction(false));
        setNoteData(data);
        setIsNote(true);
    }

    const onNameSorting = (val) => {
        dispatch(globalLoadingAction(true));
        dispatch(getTimeCaptureDetails(filterType ? parseInt(filterType) : 0, startDate ? moment(startDate).format('YYYY-MM-DD') : '', endDate ? moment(endDate).format('YYYY-MM-DD') : '', 1, perPage ? parseInt(perPage) : 20, val, !sort ? 'asc' : 'desc', props.history));
        setSordBy(val);
        setSort(!sort);
        setPageNo(1);
    }

    return (
        <>
            <div className="row">
                <div className="title-body-container mb-3">
                    <div className="title-body mb-3">
                        <h3>Realtime Capture</h3>
                        <p>Manage Company/Organization Information such as name, type, location address and contacts.</p>
                    </div>
                    <div className="title-filter-container">

                        {/* <div className="button-filter">
                         
                            <span>{hours > 9 ? hours : "0" + hours}:{minutes > 9 ? minutes : "0" + minutes}:{seconds > 9 ? seconds : "0" + seconds}</span>

                            <div className="set-capture-time">
                                {props.acknowledged ?
                                    <button type="button" onClick={() => onUpdateHandler()}><i class="fa fa-pause" aria-hidden="true"></i></button>
                                    :
                                    <button type="button" onClick={() => onSubmitHandler()}><i class="fa fa-play" aria-hidden="true"></i></button>
                                }
                            </div>
                        </div> */}
                    </div>
                </div>

                <div className="account-setting-content">
                    <Col lg={12}>
                        <Card>
                            <Card.Header>
                                <div className="add-time-capture-container">

                                    <div className="row">
                                        <div className="col-sm-4 px-1">
                                            <div className=" col-sm-12 time-capture-classification-title px-1">
                                                <h3>Client</h3>
                                            </div>
                                            <div className="add-time-capture-item">
                                                <label className='time-capture-label'></label>
                                                {/* {parseInt(props.userType) == 1 ?
                                                    <button onClick={clientModalHandler}><i class="fa fa-plus" aria-hidden="true"></i></button>
                                                    : null} */}
                                            </div>
                                            <div className="select-drop-down">
                                                <span><i class="fa fa-angle-down"></i></span>
                                                <select
                                                    defaultValue={"option"}
                                                    className={`form-control select-control ${props.acknowledged || client ? 'form-control-focus' : null}`}
                                                    value={client}
                                                    disabled={props.acknowledged}
                                                    onChange={(e) => clientHandler(e.target.value)}
                                                >
                                                    <option value="">Select client</option>
                                                    {parseInt(props.userType) == 1 ?
                                                        <option value="add">Add New</option>
                                                        : null}
                                                    <option value="0">None</option>
                                                    {
                                                        props.clientList.map((item, index) => {
                                                            return <option key={index} value={item._id}>{item.other_details[0].organization_name}</option>
                                                        })
                                                    }
                                                </select>
                                                {errors.client && <div className="text-danger fs-12">{errors.client}</div>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        {/* {props.isProject ? */}
                                        <>
                                            <div className=" col-sm-12 time-capture-classification-title px-1">
                                                <h3>Classification</h3>
                                            </div>

                                            <div className="col-sm-4 px-1">
                                                <div className="add-time-capture-item">
                                                    <label className='time-capture-label'></label>
                                                    {/* {parseInt(props.userType) == 1 ?
                                                            <button onClick={() => setProjectModal(true)}><i class="fa fa-plus" aria-hidden="true"></i></button>
                                                            : null} */}
                                                </div>

                                                <div className="select-drop-down">
                                                    <span><i class="fa fa-angle-down"></i></span>
                                                    <select
                                                        defaultValue={"option"}
                                                        className={`form-control select-control ${props.acknowledged || project ? 'form-control-focus' : null}`}
                                                        value={project}
                                                        disabled={props.acknowledged}
                                                        onChange={(e) => onProjectHandler(e.target.value)}>
                                                        <option value="">Select project</option>
                                                        {parseInt(props.userType) == 1 ?
                                                            <option value="add">Add New</option>
                                                            : null}
                                                        <option value="0">None</option>
                                                        {
                                                            props.projectList.map((item, index) => {
                                                                return <option key={index} value={item._id}>{item.project_id}</option>
                                                            })
                                                        }
                                                    </select>
                                                    {errors.project && <div className="text-danger fs-12">{errors.project}</div>}
                                                </div>
                                            </div>
                                            {/* {props.hasData ? */}
                                            <>
                                                <div className="col-sm-4 px-1">
                                                    <div className="add-time-capture-item">
                                                        <label className='time-capture-label'></label>
                                                        {/* {parseInt(props.userType) == 1 ?
                                                                    <button disabled={project ? false : true} onClick={() => setActivitieModal(true)}><i class="fa fa-plus" aria-hidden="true"></i></button>
                                                                    : null} */}
                                                    </div>
                                                    <div className="select-drop-down">
                                                        <span><i class="fa fa-angle-down"></i></span>
                                                        <select
                                                            defaultValue={"option"}
                                                            className={`form-control select-control ${props.acknowledged || activity ? 'form-control-focus' : null}`}
                                                            value={activity}
                                                            disabled={props.acknowledged}
                                                            onChange={(e) => onActivityHandler(e.target.value)}>
                                                            <option value="">Select activity</option>
                                                            {parseInt(props.userType) == 1 ?
                                                                <option value="add">Add New</option>
                                                                : null}
                                                            <option value="0">None</option>
                                                            {
                                                                props.activitiesList.map((item, index) => {
                                                                    return <option key={index} value={item._id}>{item.activity_id}</option>
                                                                })
                                                            }
                                                        </select>
                                                        {errors.activity && <div className="text-danger fs-12">{errors.activity}</div>}
                                                    </div>
                                                </div>

                                                {/* {isTask ? */}
                                                <div className="col-sm-4 px-1">
                                                    <div className="add-time-capture-item">
                                                        <label className='time-capture-label'></label>
                                                        {/* {parseInt(props.userType) == 1 ?
                                                                        <button disabled={activity ? false : true} onClick={() => setTaskModal(true)}><i class="fa fa-plus" aria-hidden="true"></i></button>
                                                                        : null} */}
                                                    </div>
                                                    <div className="select-drop-down">
                                                        <span><i class="fa fa-angle-down"></i></span>
                                                        <select
                                                            defaultValue={"option"}
                                                            className={`form-control select-control ${props.acknowledged || task ? 'form-control-focus' : null}`}
                                                            value={task}
                                                            disabled={props.acknowledged}
                                                            onChange={(e) => onTaskHandler(e.target.value)}
                                                        //onChange={(e) => setTask(e.target.value)}
                                                        >
                                                            <option value="">Select task</option>
                                                            {parseInt(props.userType) == 1 ?
                                                                <option value="add">Add New</option>
                                                                : null}
                                                            <option value="0">None</option>
                                                            {
                                                                props.taskList.map((item, index) => {
                                                                    return <option key={index} value={item._id}>{item.task_id}</option>
                                                                })
                                                            }
                                                        </select>
                                                        {errors.task && <div className="text-danger fs-12">{errors.task}</div>}
                                                    </div>
                                                </div>
                                                {/* : null} */}
                                            </>
                                            {/* : null} */}

                                            <div className='col-sm-2 time-capture-billing pt-2'>
                                                <div class="form-check custom-checkbox acco-check">
                                                    <label class="form-check-label" for="billing-check">Billable</label>
                                                    <input
                                                        type="checkbox"
                                                        className={`form-check-input form-check-time ${props.acknowledged ? 'form-control-focus' : null}`}
                                                        id="billing-check"
                                                        checked={billing}
                                                        disabled={props.acknowledged}
                                                        onChange={(e) => setBilling(e.target.checked)} />
                                                </div>

                                            </div>

                                            <div className="col-sm-10 px-1 mt-3">
                                                {/* <label className='time-capture-label'>Description</label> */}
                                                <textarea
                                                    className={`form-control ${props.acknowledged || description ? 'form-control-focus' : null}`}
                                                    rows={2}
                                                    placeholder='Description'
                                                    value={description}
                                                    disabled={props.acknowledged}
                                                    onChange={(e) => setDescription(e.target.value)} />
                                                {errors.description && <div className="text-danger fs-12">{errors.description}</div>}
                                            </div>

                                        </>
                                        {/* // : null} */}
                                        <div className='col-sm-10 pt-3'>
                                            {/* <span className='clock-time'>{hours > 9 ? hours : "0" + hours}:{minutes > 9 ? minutes : "0" + minutes}:{seconds > 9 ? seconds : "0" + seconds}</span> */}
                                            <div className="button-filter">
                                                {props.acknowledged ?
                                                    <span className='clock-time'>{hours > 9 ? hours : "0" + hours}:{minutes > 9 ? minutes : "0" + minutes}:{seconds > 9 ? seconds : "0" + seconds}</span>
                                                    :
                                                    <span className='clock-time'>Start Time</span>
                                                }
                                                <div className="set-capture-time">
                                                    {props.acknowledged ?
                                                        <button type="button" onClick={() => onUpdateHandler()}><i class="fa fa-stop" aria-hidden="true"></i></button>
                                                        :
                                                        <button type="button" disabled={client && project ? false : true} className='start-play' onClick={() => onSubmitHandler()}><i class="fa fa-play" aria-hidden="true"></i></button>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className='col-sm-2 time-capture-billing pt-2'>
                                        <div class="form-check custom-checkbox acco-check">
                                            <label class="form-check-label" for="billing-check">Billable</label>
                                            <input type="checkbox" class="form-check-input" id="billing-check" value={billing} onChange={(e) => setBilling(e.target.checked)} />
                                        </div>

                                    </div> */}
                                    </div>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <div className="total-time-record">
                                    <h3>Transaction Log</h3>
                                    <span>Total: <strong>{getTotalTime()}</strong></span>
                                </div>
                                <div className="time-capture-filter-container">
                                    <div className="time-capture-filter-btn">
                                        <button disabled={props.acknowledged} style={{ backgroundColor: filterType == 1 ? '#787776' : '#9d9d9d' }} onClick={() => onFilterTypeHandler(1)} type='button'>Today</button>
                                        <button disabled={props.acknowledged} style={{ backgroundColor: filterType == 2 ? '#787776' : '#9d9d9d' }} onClick={() => onFilterTypeHandler(2)} type='button'>Yesterday</button>
                                        <button disabled={props.acknowledged} style={{ backgroundColor: filterType == 3 ? '#787776' : '#9d9d9d' }} onClick={() => onFilterTypeHandler(3)} type='button'>7D</button>
                                        <div className="select-drop-down filter-drop-down">
                                            <span><i class="fa fa-angle-down"></i></span>
                                            <select
                                                defaultValue={"option"}
                                                className="form-control filter-control"
                                                value={perPage}
                                                disabled={props.acknowledged}
                                                onChange={(e) => onPageHandler(e.target.value)}>
                                                <option value="">Per page</option>
                                                <option value="10">10</option>
                                                <option value="20">20</option>
                                                <option value="50">50</option>
                                                <option value="0">All</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="date-control-view filter-date-control">
                                        <DatePicker
                                            ref={calendarRef}
                                            placeholderText="Select date"
                                            selected={startDate}
                                            onChange={onDateHandler}
                                            startDate={startDate}
                                            endDate={endDate}
                                            selectsRange
                                            disabled={props.acknowledged}
                                        />
                                        <button className="picker-btn"
                                            onClick={() => calendarRef.current.setOpen(true)}
                                        >
                                            <i class="fa-solid fa-calendar-days"></i>
                                        </button>

                                    </div>
                                    <div className="time-capture-filter-btn">
                                        <button
                                            type='button'
                                            className='time-capture-reset-btn'
                                            disabled={filterType != 0 || startDate ? false : true}
                                            onClick={() => onCleanHandler()}
                                        >Reset</button>

                                    </div>
                                </div>
                               
                                {props.screenVisible ?
                                    <>
                                        {props.list.length > 0 ?
                                            <Table responsive className='time-capture-table'>
                                                <thead>
                                                    <tr>

                                                        <th>
                                                            <button type="button" onClick={() => onNameSorting('client_first_name')} className="sorting-btn">
                                                                <strong>Client</strong>
                                                                <span className={`sorting-icon-groups ${sordBy === 'client_first_name' ? sort ? "sorting-asc" : "sorting-dec" : null}`}>
                                                                    <i class="fa fa-sort-asc" aria-hidden="true"></i>
                                                                    <i class="fa fa-sort-desc" aria-hidden="true"></i>
                                                                </span>
                                                            </button>
                                                        </th>
                                                        <th>
                                                            <button type="button" onClick={() => onNameSorting('project_name')} className="sorting-btn">
                                                                <strong>Project</strong>
                                                                <span className={`sorting-icon-groups ${sordBy === 'project_name' ? sort ? "sorting-asc" : "sorting-dec" : null}`}>
                                                                    <i class="fa fa-sort-asc" aria-hidden="true"></i>
                                                                    <i class="fa fa-sort-desc" aria-hidden="true"></i>
                                                                </span>
                                                            </button>
                                                        </th>
                                                        <th>
                                                            <button type="button" onClick={() => onNameSorting('activity')} className="sorting-btn">
                                                                <strong>Activity</strong>
                                                                <span className={`sorting-icon-groups ${sordBy === 'activity' ? sort ? "sorting-asc" : "sorting-dec" : null}`}>
                                                                    <i class="fa fa-sort-asc" aria-hidden="true"></i>
                                                                    <i class="fa fa-sort-desc" aria-hidden="true"></i>
                                                                </span>
                                                            </button>
                                                        </th>
                                                        <th>
                                                            <button type="button" onClick={() => onNameSorting('task')} className="sorting-btn">
                                                                <strong>Task</strong>
                                                                <span className={`sorting-icon-groups ${sordBy === 'task' ? sort ? "sorting-asc" : "sorting-dec" : null}`}>
                                                                    <i class="fa fa-sort-asc" aria-hidden="true"></i>
                                                                    <i class="fa fa-sort-desc" aria-hidden="true"></i>
                                                                </span>
                                                            </button>
                                                        </th>
                                                        <th>
                                                            <strong>Time</strong>
                                                        </th>
                                                        <th>
                                                            <strong>Total</strong>
                                                        </th>
                                                        <th>
                                                            <strong>Billable</strong>
                                                        </th>
                                                        <th>
                                                            <strong>Capture</strong>
                                                        </th>
                                                        <th>
                                                            <strong>Actions</strong>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        props.list.map((item, index) => {
                                                            return (
                                                                <tr key={index}
                                                                    className={props.acknowledged && (timeData._id == item._id) ? "activeRow" : null}
                                                                >
                                                                    <td>{item.client_first_name ? item.client_first_name : "None"}</td>
                                                                    <td>{item.project_name ? item.project_name : "None"}</td>
                                                                    <td>{item.activity ? item.activity : "None"}</td>
                                                                    <td>{item.task ? item.task : "None"}</td>
                                                                    <td>{moment(item.start_time).format('LT')} - {moment(item.end_time).format('LT')}</td>
                                                                    <td>{item.time_difference}</td>
                                                                    <td>
                                                                        <div className="form-check custom-checkbox checkbox-select mx-2">
                                                                            <input
                                                                                type="checkbox"
                                                                                className="form-check-input capture-check-input"
                                                                                id={`check${index}`}
                                                                                checked={item.billable === 0 ? false : true}
                                                                                disabled={props.acknowledged}
                                                                                onClick={(e) => onBillableHandler(e, item)}
                                                                            />
                                                                            <label
                                                                                className="form-check-label"
                                                                                htmlFor={`check${index}`}
                                                                            ></label>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <button className='time-capture-play-btn' onClick={() => onPlayHandler(item, index)} disabled={props.acknowledged}><i class="fa fa-play" aria-hidden="true"></i></button>
                                                                        {/* <button><i class="fa fa-pause-circle-o" aria-hidden="true"></i></button> */}
                                                                    </td>
                                                                    <td>
                                                                        <div className="d-flex">
                                                                            <Button
                                                                                onClick={() => onEditHandler(item)}
                                                                                className="btn btn-warning shadow btn-xs sharp me-1"
                                                                                disabled={props.acknowledged || (item.approve_status == 0 && item.send_to_reviewer == 1) || (item.approve_status == 1 && item.send_to_reviewer == 1) || (item.approve_status == 3 && item.send_to_reviewer == 1) ? true : false}
                                                                            >
                                                                                <i className="fas fa-pencil-alt"></i>
                                                                            </Button>
                                                                            <Button
                                                                                className="btn btn-danger shadow btn-xs sharp me-1"
                                                                                onClick={() => onDeleteHandler(item._id)}
                                                                                disabled={props.acknowledged || (item.approve_status == 0 && item.send_to_reviewer == 1) || (item.approve_status == 1 && item.send_to_reviewer == 1) || (item.approve_status == 3 && item.send_to_reviewer == 1) ? true : false}
                                                                            >
                                                                                <i className="fa fa-trash"></i>
                                                                            </Button>
                                                                            {/* <Button
                                                                                className="btn btn-success shadow btn-xs sharp me-1"
                                                                                onClick={() => onAssignHandler(item)}
                                                                                disabled={props.acknowledged || (item.approve_status == 0 && item.send_to_reviewer == 1) || (item.approve_status == 1 && item.send_to_reviewer == 1) || (item.approve_status == 3 && item.send_to_reviewer == 1) ? true : false}
                                                                            >
                                                                                <i class="fa fa-user-plus" aria-hidden="true"></i>
                                                                            </Button> */}
                                                                            {/* <Button
                                                                                className="btn btn-info shadow btn-xs sharp me-1"
                                                                                onClick={() => onGetNoteHandler(item._id)}
                                                                                disabled={props.acknowledged || item.send_to_reviewer == 0 ? true : false}
                                                                            >
                                                                                <i class="fa fa-comment" aria-hidden="true"></i>
                                                                            </Button> */}
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })
                                                    }
                                                </tbody>
                                            </Table>
                                            :
                                            <div className="nodata-found-view">
                                                <h3>No Transactions</h3>
                                            </div>
                                        }
                                    </>
                                    : null}

                                <div className="pagination-container">
                                    {parseInt(props.totalRecord) > 0 ? (
                                        <div className="page-result alignResult">
                                            <p>Showing{" "}{(pageNo - 1) * perPage + 1 + " - " + ((pageNo - 1) * perPage + props.list.length) + " of " + "( " + props.totalRecord + " items )"}</p>
                                        </div>
                                    ) : (
                                        <div></div>
                                    )}

                                    {parseInt(props.totalRecord) > 10 ? (
                                        <ReactPaginate
                                            containerClassName="pagination-gutter false pagination-circle pagination"
                                            pageClassName="page-item"
                                            pageLinkClassName="page-link"
                                            breakLabel="..."
                                            nextLabel={<i class="fa fa-angle-right" aria-hidden="true"></i>}
                                            nextClassName="page-item"
                                            nextLinkClassName="page-link"
                                            previousLabel={<i class="fa fa-angle-left" aria-hidden="true"></i>}
                                            previousClassName="page-item"
                                            previousLinkClassName="page-link"
                                            breakClassName="page-item"
                                            breakLinkClassName="page-link"
                                            renderOnZeroPageCount={null}
                                            activeClassName="active"
                                            onPageChange={handlePageClick}
                                            pageRangeDisplayed={3}
                                            pageCount={props.totalPage}
                                            //initialPage={active - 1}
                                            forcePage={pageNo - 1}
                                        />
                                    ) : null}
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </div>

                <Modal
                    className="fade bd-example-modal-sm"
                    size="lg"
                    show={addModal}
                    onHide={() => setAddModal(false)}
                >
                    <div className="add-user-modal-form-view client-modal">
                        <button onClick={() => setAddModal(false)} className="modal-cross-btn"><i class="fa fa-times" aria-hidden="true"></i></button>
                        <div className="modal-title-view">
                            <h3>Update time capture</h3>
                        </div>
                        <div className="basic-form">
                            <form onSubmit={onUpdateData}>
                                <div className="row">

                                    <div className="form-group form-group-modal-custom mb-3 col-6">
                                        <label className="mb-1">
                                            <strong>Client</strong>
                                        </label>
                                        <div className="select-drop-down">
                                            <span><i class="fa fa-angle-down"></i></span>
                                            <select
                                                defaultValue={"option"}
                                                className="form-control select-control"
                                                value={updateClient}
                                                onChange={(e) => setUpdateClient(e.target.value)}>
                                                <option value="0">None</option>
                                                {
                                                    props.clientList.map((item, index) => {
                                                        return <option key={index} value={item._id}>{item.first_name}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>

                                    <div className="form-group form-group-modal-custom mb-3 col-6">
                                        <label className="mb-1">
                                            <strong>Project</strong>
                                        </label>
                                        <div className="select-drop-down">
                                            <span><i class="fa fa-angle-down"></i></span>
                                            <select
                                                defaultValue={"option"}
                                                className="form-control select-control"
                                                value={updateProject}
                                                onChange={(e) => onUpdateProjectHandler(e.target.value)}>
                                                <option value="0">None</option>
                                                {
                                                    props.projectList.map((item, index) => {
                                                        return <option key={index} value={item._id}>{item.project_id}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>

                                    <div className="form-group form-group-modal-custom mb-3 col-6">
                                        <label className="mb-1">
                                            <strong>Activity</strong>
                                        </label>
                                        <div className="select-drop-down">
                                            <span><i class="fa fa-angle-down"></i></span>
                                            <select
                                                defaultValue={"option"}
                                                className="form-control select-control"
                                                value={updateActivity}
                                                onChange={(e) => onUpdateActivityHandler(e.target.value)}>
                                                <option value="0">None</option>
                                                {
                                                    props.activitiesList.map((item, index) => {
                                                        return <option key={index} value={item._id}>{item.activity_id}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>

                                    <div className="form-group form-group-modal-custom mb-3 col-6">
                                        <label className="mb-1">
                                            <strong>Task</strong>
                                        </label>
                                        <div className="select-drop-down">
                                            <span><i class="fa fa-angle-down"></i></span>
                                            <select
                                                defaultValue={"option"}
                                                className="form-control select-control"
                                                value={updateTask}
                                                onChange={(e) => setUpdateTask(e.target.value)}>
                                                <option value="0">None</option>
                                                {
                                                    props.taskList.map((item, index) => {
                                                        return <option key={index} value={item._id}>{item.task_id}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>

                                    <div className="form-group form-group-modal-custom mb-3 col-6">
                                        <label className="mb-1">
                                            Start time
                                        </label>
                                        <div className="form-calendar">
                                            <DatePicker
                                                ref={startDateRef}
                                                selected={updateStartDate}
                                                timeInputLabel="Time:"
                                                className="form-control"
                                                placeholderText=""
                                                dateFormat="MM/dd/yyyy h:mm aa"
                                                showTimeInput
                                                onChange={(date) => setUpdateStartDate(date)}
                                            />
                                            <button type='button' className="form-calendar-btn"
                                                onClick={() => startDateRef.current.setOpen(true)}
                                            >
                                                <i class="fa-regular fa-calendar-days"></i>
                                            </button>
                                        </div>
                                    </div>

                                    <div className="form-group form-group-modal-custom mb-3 col-6">
                                        <label className="mb-1">
                                            End time
                                        </label>
                                        <div className="form-calendar">
                                            <DatePicker
                                                ref={endDateRef}
                                                selected={updateEndDate}
                                                timeInputLabel="Time:"
                                                className="form-control"
                                                placeholderText=""
                                                dateFormat="MM/dd/yyyy h:mm aa"
                                                showTimeInput
                                                onChange={(date) => setUpdateEndDate(date)}
                                            />
                                            <button type='button' className="form-calendar-btn"
                                                onClick={() => endDateRef.current.setOpen(true)}
                                            >
                                                <i class="fa-regular fa-calendar-days"></i>
                                            </button>
                                        </div>
                                    </div>

                                    <div className="form-group mb-3 col-12">
                                        <label className="mb-1">
                                            <strong>Description</strong>
                                        </label>
                                        <textarea
                                            className="form-control"
                                            rows={2}
                                            //placeholder='Description'
                                            value={updateDes}
                                            onChange={(e) => setUpdateDes(e.target.value)} />
                                    </div>

                                    <div className='col-sm-12'>
                                        <div class="form-check custom-checkbox">
                                            <input type="checkbox"
                                                className="form-check-input"
                                                id="update-billing-check"
                                                checked={updateBilling}
                                                onChange={(e) => setUpdateBilling(e.target.checked)} />
                                            <label className="form-check-label"
                                                for="update-billing-check">Billable</label>
                                        </div>

                                    </div>

                                </div>

                                <div className="modal-button-group mt-4">
                                    <button type="button" onClick={() => setAddModal(false)} className="btn form-cencel-btn">
                                        Cancel
                                    </button>
                                    <button type="submit" className="btn btn-primary role-form-btn">
                                        Update
                                    </button>
                                </div>
                            </form>
                        </div>

                    </div>
                </Modal>

                <AddClient
                    show={clientModal}
                    action={1}
                    submitHandler={(data) => onClientHandler(data)}
                    closeHandler={() => setClientModal(false)}
                />

                <AddProject
                    show={projectModal}
                    action={1}
                    submitHandler={(data) => onAddProjectHandler(data)}
                    closeHandler={() => setProjectModal(false)}
                />

                <AddActivitie
                    show={activitieModal}
                    action={1}
                    submitHandler={(data) => onAddActivitieHandler(data)}
                    closeHandler={() => setActivitieModal(false)}
                />

                <AddTask
                    show={taskModal}
                    //data={details}
                    action={1}
                    option={optionsArray}
                    submitHandler={(data) => onAddTaskHandler(data)}
                    closeHandler={() => setTaskModal(false)}
                />

            </div>
            <Modal
                className="fade bd-example-modal-sm"
                size="sm"
                show={isConfirm}
            >
                <div className='add-subcription-plan'>
                    <h4>Start clock again for selected transaction.</h4>
                    <div className='subcription-plan-btn-group'>
                        <button type="button"
                            //onClick={() => setIsConfirm(false)} 
                            onClick={() => onCancelHandler()}
                            className="btn">
                            No
                        </button>
                        <button
                            type="button"
                            onClick={() => onConfirmHandler()}
                            className="btn confirm-btn">
                            Yes
                        </button>
                    </div>

                </div>
            </Modal>

            <DateRangeModal
                show={isDateRange}
                onClose={() => setIsDateRange(false)}
            />

            <ChatBox
                show={isNote}
                data={noteData}
                value={message}
                onChangeHandler={(val) => setMessage(val)}
                onSubmitHandler={() => onSubmitMessageHandler()}
                closeHandler={closeHandler}
                loading={loading}
                currentUser={props.userId}
            />

        </>
    )
}

const mapStateToProps = (state) => {
    return {
        screenVisible: state.timeCapture.screenVisible,
        showLoading: state.role.showLoading,
        projectList: state.project.projectList,
        activitiesList: state.project.activitiesList,
        list: state.timeCapture.list,
        totalPage: state.timeCapture.totalPage,
        totalRecord: state.timeCapture.totalRecord,
        acknowledged: state.timeCapture.acknowledged,
        insertedId: state.timeCapture.insertedId,
        taskList: state.project.taskList,
        clientList: state.clients.clientList,
        userType: state.auth.userType,
        groupKeeperList: state.employee.groupKeeperList,
        configDetails: state.systemconfig.configDetails,
        hasData: state.project.hasData,
        systemAdmin: state.auth.systemAdmin,
        projectDetails: state.project.projectDetails,
        isProject: state.project.isProject,
        userId: state.auth.userId,
    };
};
export default connect(mapStateToProps)(TimeCapture);