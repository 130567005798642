export const CHECK_TOKEN_EXIST_API = "users/check-login";

//param - null
export const GET_COUNTRIES_LIST_API = "countries";

//param - null
export const GET_STATES_LIST_API = "states";

//param - null
export const GET_CITYS_LIST_API = "cities";

//param - email
export const SET_EMAIL_OTP_API = "email_verification/send-otp";

//param - email,OTP
export const VERIFY_OTP_API = "email_verification/verify-otp";

//param - email,country,password
export const USER_REGISTRATION_API = "users/registration";

//param - email,password
export const USER_LOGIN_API = "users/login";

//param - email,password
export const GOOGLE_LOGIN_API = "users/social-login";

//param - first_name, last_name, phone_no
export const USER_SETUP_ACCOUNT_API = "users/setup-account";

//param - user_id,company_name, company_phone, company_address,designation,country,state,city,pincode,industry,website_url,address1,address2
export const COMPANY_SETUP_DETAILS_API = "company/set-organization-details";

//param - user_id
export const GET_USER_PROFILE_DETAILS_API = "users/profile-details";

//param - email
export const SEND_FORGOT_PASSWORD_OTP_API = "email_verification/send-forgetpassword-otp";

//param - email,otp
export const VERIFY_FORGOT_PASSWORD_OTP_API = "email_verification/verify-forgetpassword-otp";

//param - email,password
export const RESET_PASSWORD_API = "users/reset-password";

//param - user_id,old_password, new_password
export const CHANGE_PASSWORD_API = "users/change-password";

//param - NULL
export const GET_PERMISSION_LIST_API = "permissions";

//param - user_id
export const GET_PREVILEDGE_API = "users/get-previledge";

//param - role_name,company_id,permissions
export const ADD_ROLE_API = "roles/add";

//param - role_id, role_name, permissions
export const UPDATE_ROLE_API = "roles/update";

//param - company_id
export const GET_ROLE_API = "roles";

//param - company_id
export const GET_ACTIVE_ROLE_API = "roles/get-active-roles";

//param - role_id, status
export const UPDATE_ROLE_STATUS_API = "roles/change-status";

//param - role_id
export const REMOVE_ROLE_API = "roles/remove";

//param - role_id,role_name
export const VERIFY_ROLE_NAME_API = "roles/check_duplicate_role";

//param - role_id, name, email, employee_id, password
export const ADD_EMPLOYEE_API = "employee/add";

//param - emp_id,role_id, name, email, password
export const UPDATE_EMPLOYEE_API = "employee/update";

//param -employee_id,
export const GET_EMPLOYEE_LIST_API = "employee";

//param -employee_id,
export const GET_REPORT_TO_EMPLOYEE_LIST_API = "employee/report_to_list";

//param - emp_id, status
export const UPDATE_EMPLOYEE_STATUS_API = "employee/change-status";

//param - emp_id
export const REMOVE_EMPLOYEE_RECORD_API = "employee/remove";

//param - userIds
export const REMOVE_MULTIPLE_EMPLOYEE_RECORD_API = "employee/remove_multiple_record";

//param - userIds
export const DOWNLOAD_EMPLOYEE_RECORD_API = "employee/export_record";

//param - emp_id
export const VERIFY_EMPLOYEE_ID_API = "employee/check_duplicate_empid";

//param -  user_id,email,
export const VERIFY_USERS_EMAIL_API = "users/check_duplicate_email";

//param -employee_id,
export const GET_APPROVER_LIST_API = "employee/approver_list";

//param -employee_id,
export const GET_KEEPER_LIST_API = "employee/keeper_list";

//param -employee_id,
export const GET_REVIEWER_LIST_API = "employee/reviwer_list";

//param - first_name, last_name, phone_no, designation, country, country_code, profile_pic
export const UPDATE_PROFILE_API = "users/update-profile-details";

//param - company_id, company_name, address1, address2, country, country_code, state, city, company_phone, pincode, industry, website_url
export const UPDATE_CONPANY_DETAILS_API = "company/update-company-details";

//param - user_id
export const CHECK_SUBSCRIPTION_API = "users/check-subscription";

//param - 
export const CANCEL_SUBSCRIPTION_API = "users/cancel_subscription";

//param - user_id,subscription_id
export const GET_SUBSCRIPTION_API = "users/add-subscription";

//param - company_id,client_id, client_alt, first_name, last_name, email, mobile, status, password
export const ADD_CLIENTS_API = "clients/add-client";

//param - user_id,client_id, client_alt, first_name, last_name, email, mobile, status, password
export const UPDATE_CLIENTS_API = "clients/update-record";

//param - company_id
export const GET_CLIENTS_API = "clients/client-list";

//param - client_id,status
export const UPDATE_CLIENTS_STATUS_API = "clients/change-client-status";

//param - client_id
export const REMOVE_CLIENTS_API = "clients/remove-record";

//param - userIds
export const DOWNLOAD_CLIENTS_RECORD_API = "clients/export_record";

//param - title, description, client_id, group_id, start_date, end_date
export const ADD_PROJECT_API = "projects/create-project";

//param - project_id,title, description, client_id, group_id, start_date, end_date,status
export const UPDATE_PROJECT_API = "projects/update-project-details";

//param - null
export const GET_PROJECT_API = "projects";

//param - null
export const GET_SUPERVISOR_TIMECARD_API = "timetracker/supervison-timecard";

//param - null
export const GET_APPROVER_TIMECARD_API = "timetracker/approver-timecard";

//param - null
export const GET_REVIEWER_TIMECARD_API = "timetracker/reviewer-timecard";

//param - project_id
export const GET_PROJECT_DETAILS_API = "projects/get-project-details-by-id";

//param - client_id
export const GET_PROJECT_LIST_BY_CLIENT_API = "projects/project_list_by_client";

//param - project_id,status
export const UPDATE_PROJECT_STATUS_API = "projects/change-project-status";

//param - project_id
export const REMOVE_PROJECT_API = "projects/remove-project";

//param - project_id,title, description,start_date, end_date
export const ADD_ACTIVITIES_API = "activities/create-activity";

//param - activity_id,title, description,start_date, end_date,status
export const UPDATE_ACTIVITIES_API = "activities/update-activity-details";

//param - project_id
export const CHECK_PROJECT_ACTIVITIES_EXISTANCE_API = "activities/check_activity_existance";

//param - project_id
export const PROJECT_ACTIVITIES_LIST_API = "activities";

//param - project_id,status
export const UPDATE_PROJECT_ACTIVITIES_STATUS_API = "activities/change-activity-status";

//param - activity_id
export const REMOVE_PROJECT_ACTIVITIES_API = "activities/remove-activity";

//param - project_id, activity_id, title, description, activity, time_keepers, status
export const ADD_TASK_API = "tasks/create-task";

//param - task_id, title, description, activity, time_keepers, status
export const UPDATE_TASK_API = "tasks/update-task-details";

//param - activity_id
export const TASK_LIST_API = "tasks";

//param - task_id
export const GET_TASK_DETAILS_BY_ID_API = "tasks/get-task-details-by-id";

//param - project_id
export const GET_PROJECT_DETAILS_BY_ID_API = "projects/get-project-details-by-id";

//param - activity_id
export const ACCESSABLE_TASK_LIST_API = "tasks/accessable_task_list";

//param - task_id
export const USED_TASK_DATA_API = "tasks/check_aleady_used_or_not";

//param - task_id,status
export const UPDATE_TASK_STATUS_API = "tasks/change-task-status";

//param - task_id
export const REMOVE_TASK__API = "tasks/remove-task";

//param - userIds
export const DOWNLOAD_PROJECTS_RECORD_API = "projects/export_record";

//param - userIds
export const DOWNLOAD_ACTIVITIES_RECORD_API = "activities/export_record";

//param - userIds
export const DOWNLOAD_TASKS_RECORD_API = "tasks/export_record";

//param - projectIds
export const REMOVE_MULTIPLE_PROJECTS_RECORD_API = "projects/remove_multiple_record";

//param - activityIds
export const REMOVE_MULTIPLE_ACTIVITIES_RECORD_API = "activities/remove_multiple_record";

//param - taskIds
export const REMOVE_MULTIPLE_TASKS_RECORD_API = "tasks/remove_multiple_record";

//param - title, description, employee_ids,
export const ADD_TEAM_API = "employee-group/create-group";

//param - group_id,title, description, employee_ids,status
export const UPDATE_TEAM_API = "employee-group/update-group-details";

//param - company_id
export const GET_TEAM_LIST_API = "employee-group";

//param - company_id
export const GET_ACTIVE_TEAM_LIST_API = "employee-group/get-active-group-list";

//param - group_id
export const GET_KEEPER_TEAM_LIST_API = "employee/group_keeper_list";

//param - group_id
export const GET_TEAM_LIST_BY_ID_API = "employee-group/get-group-details-by-id";

//param - group_id, status
export const UPDATE_TEAM_STATUS_API = "employee-group/change-group-status";

//param - group_id
export const REMOVE_TEAM_RECORD_API = "employee-group/remove-group";

//param - groupIds
export const REMOVE_MULTIPLE_TEAM_RECORD_API = "employee-group/remove_multiple_record";

//param - userIds
export const DOWNLOAD_TEAM_RECORD_API = "employee-group/export_record";

//param - company_id,address_1, address_2, country, state, city, postal_code, status
export const ADD_LOCATION_API = "locations/add-location";

//param - company_id,type,name
export const ADD_LOCATION_TYPE_API = "locationtype/add-location-type";

//param - company_id
export const GET_LOCATION_TYPE_API = "locationtype";

//param - location_id,company_id,address_1, address_2, country, state, city, postal_code, status
export const UPDATE_LOCATION_API = "locations/update-location-details";

//param - company_id
export const GET_LOCATION_LIST_API = "locations";

//param - location_id, status
export const UPDATE_LOCATION_STATUS_API = "locations/change-location-status";

//param - location_id
export const REMOVE_LOCATION_RECORD_API = "locations/remove-location";

//param - locationIds
export const DOWNLOAD_LOCATION_RECORD_API = "locations/export_location";

//param - locationIds
export const REMOVE_MULTIPLE_LOCATION_RECORD_API = "locations/remove_multiple_record";

//param - project_id,description,start_time
export const ADD_TIME_TRACKER_API = "timetracker/add-time";

//param - project_id,description,start_time
export const ADD_USER_TIME_TRACKER_API = "timetracker/start-user-time-tracking";

//param - track_id,project_id,description,end_time
export const UPDATE_TIME_TRACKER_API = "timetracker/update-time";

//param - track_id,project_id,description,end_time
export const UPDATE_USER_TIME_TRACKER_API = "timetracker/stop-user-time-tracking";

//param - track_id,project_id,description,end_time
export const UPDATE_TIME_TRACKER_LIST_API = "timetracker/update-time-tracking";

//param - track_id,approve_status
export const UPDATE_TIME_TRACKER_APPROVE_STATUS_API = "timetracker/update-approve-status";

//param - track_id,approve_status
export const UPDATE_MULTIPLE_TIME_TRACKER_APPROVE_STATUS_API = "timetracker/update-multiple-approve-status";

//param - track_id
export const SEND_TO_REVIEWER_API = "timetracker/send-to-reviewer";

//param - track_id
export const REVIEWER_APPROVE_TRACKING_DETAILS_API = "timetracker/approve-tracking-details";

//param - track_id
export const SEND_BACK_TO_APPROVER_API = "timetracker/send-back-from-approver";

//param - track_id
export const SEND_TO_APPROVER_API = "timetracker/send-to-approver";

//param - per_page,page_no
export const GET_REVIEWER_TIME_TRACKER_LIST_API = "timetracker/approved-tracking-details-for-reviewer";

//param - per_page,page_no
export const GET_APPROVER_TIME_TRACKER_LIST_API = "timetracker/tracking-details-for-approver";

//param - per_page,page_no,orderByField, orderByFieldValue
export const GET_PENDING_TIMETRACKER_LIST_FOR_APPROVER_API = "timetracker/pending-tracking-details-for-approver";


//param - per_page,page_no,orderByField, orderByFieldValue
export const GET_APPROVED_TIMETRACKER_LIST_FOR_APPROVER_API = "timetracker/approved-tracking-details-for-approver";

//param - per_page,page_no,orderByField, orderByFieldValue
export const GET_REJECT_TIMETRACKER_LIST_FOR_APPROVER_API = "timetracker/rejected-tracking-details-for-approver";

//param - track_id,project_id,description,end_time
export const UPDATE_USER_TIME_TRACKER_LIST_API = "timetracker/update-user-time-tracking";

//param -
export const GET_TIME_TRACKER_API = "timetracker";

//param -
export const GET_USER_TIME_TRACKER_API = "timetracker/tasktracklist";

//param -
export const ADD_BILLABLE_TIME_TRACKER_API = "timetracker/update-billable-status";

//param -
export const GET_ASSIGNED_TASK_API = "timetracker/user_assigned_task_list";

//param - track_id
export const REMOVE_TIME_TRACKER_API = "timetracker/remove-record";

//param - user_id
export const ADD_TIME_CARD_DETAILS_API = "timecard/add-timesheet";

//param - time_track_id
export const UPDATE_TIME_CARD_DETAILS_API = "timecard/edit-timesheet";

//param - user_id
export const ADD_OWN_TIME_CARD_DETAILS_API = "timecard/add-own-timesheet";

//param - user_id
export const ADD_USER_TIME_CARD_DETAILS_API = "timecard/add-user-timesheet";

//param - user_id
export const ADD_USER_BULK_TIME_CARD_DETAILS_API = "timecard/add-user-bulk-timesheet";

//param - user_id
export const ADD_OWN_BULK_TIME_CARD_DETAILS_API = "timecard/add-own-multiple-timesheet";

//param - time_track_id
export const UPDATE_OWN_TIME_CARD_DETAILS_API = "timecard/edit-own-timesheet";

//param - time_track_id
export const UPDATE_USER_TIME_CARD_DETAILS_API = "timecard/edit-user-timesheet";

//param - time_track_id
export const REMOVE_TIME_CARD_DETAILS_API = "timecard/delete-timesheet";

//param - user_id
export const GET_TIME_CARD_DETAILS_API = "timecard/timesheet";

//param - user_id
export const GET_MANUAL_TIMESHEET_API = "timetracker/manual-timesheet";

//param - company_id
export const GET_TIME_CARD_LIST_API = "timecard";

//param - config_data
export const ADD_SYSTEM_CONFIG_API = "filteroptions/add_config";

//param - 
export const GET_SYSTEM_CONFIG_LIST_API = "filteroptions";

//param - filter_type
export const GET_SYSTEM_USER_CONFIG_API = "filteroptions/get_user_config";

//param - user_id,permissions
export const ADD_PREVILEGE_API = "users/add-previlege";

//param - user_id
export const GET_PREVILEGE_API = "users/get-user-previlege";

//param - track_id
export const ADD_TRANSACTION_LOG_NOTE_API = "transactionlognotes/store";

//param - track_id
export const GET_TRANSACTION_LOG_NOTE_API = "transactionlognotes";

//param - track_id
export const GET_REVIEWER_PROJECT_LIST_API = "timetracker/reviewer-project-list";

//param - project_id
export const GET_TIME_CARD_DETAILS_FOR_REVIEWER_API = "timetracker/project-tracking-details-for-reviewer";

//param - project_id
export const GET_APPROVER_PROJECT_LIST_API = "timetracker/approver-project-list";

//param - project_id, type
export const GET_APPROVER_PROJECT_TRACKER_DETAILS_API = "timetracker/project-tracking-details-for-approver";




